import React, { ReactElement } from "react";

import Image from "next/image";
const benefits = [
    {
        title: "Save Money",
        content:
            "A home warranty can save money by covering the cost of repairs or replacements, providing discounts on regular maintenance services, and offering coverage for pre-existing conditions. With a home warranty, homeowners can avoid paying these high costs and pay a lower service fee.",
    },
    {
        title: "Save Time",
        content:
            "Home warranties save homeowners time by streamlining repairs and replacements. With a warranty, homeowners can easily submit a claim and have a technician dispatched promptly, eliminating the need for time-consuming searches and scheduling.",
    },
    {
        title: "Bonus Benefits ",
        content:
            "Some home warranty companies offer additional benefits to sweeten the deal. Homeowners may be eligible for exclusive discounts on home security products, restaurants, or popular products. However, these benefits vary based on the home warranty company and chosen plan.",
    },
];
export default function HomeWarrantyBenefits(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row lg:gap-24 gap-12  lg:mt-28 mt-14 ">
            <div className="w-[250px] h-[350px] lg:w-[449px] lg:min-w-[449px] lg:h-[674px]  relative  mx-auto lg:mx-0 order-2 lg:order-1">
                <Image
                    src={
                        "/homewarranty.center/assets/images/homeWarrantyBenefitsImg.png"
                    }
                    alt="Image"
                    layout="fill"
                />
            </div>
            <div className="max-w-[638px] mx-auto lg:mx-0 order-1 lg:order-2">
                {benefits.map((point, index) => (
                    <div key={index} className=" mt-14 first-of-type:mt-0">
                        <div className="flex gap-3 items-end">
                            <span className="block w-[6px] h-[42px] bg-[#F6CEBE]"></span>
                            <h2 className="font-bold lg:text-2xl text-lg   ">
                                {point.title}
                            </h2>
                        </div>

                        <p className="mt-5 font-light lg:text-lg  text-base lg:leading-8 text-[#505050]">
                            {point.content}
                        </p>
                    </div>
                ))}
            </div>
        </section>
    );
}
